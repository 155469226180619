
/*
 * Blog 
 * -----------------------------------------------
*/

.post {

	.entry-header {
		position: relative;
		overflow: hidden;
	}
	.entry-header-new {
		clear: both;
	    margin-bottom: 45px;
	    position: relative;
	    width: 100%;

		.blog-author {
		    bottom: -30px;
		    height: 60px;
		    left: 0;
		    margin: auto;
		    padding: 1px;
		    position: absolute;
		    right: 0;
		    width: 60px;
		    z-index: 3;
			img {
				border-radius: 50%;
				max-width: 100%;
    			width: 100%;
			}
		}
	}
	.entry-title {
		margin-top: 0;
	}

	.entry-content {
		background: @white-f8;
		padding: 15px;
	}
	.entry-date {
		font-size: 13px;
	}

	.entry-meta {
		&.meta-absolute {
			position: absolute;
			left: 0;
			top: 0;

			i {
				display: block;
				font-size: 28px;
			}
			.display-table-cell {
				ul li a {					
				color: @white-base;
				font-size: 16px;
				font-weight : 600;
				}
			}

			span a {
				color: #454545;
			    font-size: 12px;
			    font-style: italic;
			    letter-spacing: 2px;
			    margin-right: 5px;
			}
		}
	}
		.entry-date {
			li {
				font-weight : 600;
			}

		}
	&.media-post  {

		.post-thumb {
			float: left;    
			margin: 0 10px 10px 0;
		}
	}

	.post-thumb {
		.transition(all .2s ease-in-out); 
	}

	&:hover {
		.post-thumb {
		}
	}

}

/*
 * Blog Style 2
 * -----------------------------------------------
*/
.post.style2,.post.style3 {
	position: relative;
	overflow: hidden;
	.entry-content {
		background-color: fade(@black, 80%);
		bottom: -30px;
		left: 0;
		padding: 30px;
		position: absolute;
		right: 0;
		.transition(all 0.3s ease-in-out 0s);
		
		.entry-date {
			font-size: 11px;
			margin-bottom: 10px;
		}
	}
	&:hover {
		.entry-content {
			bottom: 0;
		}
		.post-thumb
				
		 {
			.scale(1);
		}
	}

}

.post .entry-header .entry-date {
  position: absolute;
  bottom: 0;
  right: 0;
}

.featured-blog {
	overflow: hidden;
	position: relative;
}
.featured-blog-details {
	bottom: -18%;
	padding: 30px;
	position: absolute;
	transition: all 400ms ease-in-out 0s;
	width: 100%;
}
.featured-blog:hover .featured-blog-details {
  bottom: 0;
}
.reply-box .form-group input {
	border-radius: 0;
	height: 45px;
	margin-bottom: 30px;
}

.featured-blog {
	overflow: hidden;
	position: relative;
}
.featured-blog-details {
	bottom: -18%;
	padding: 30px;
	position: absolute;
	transition: all 400ms ease-in-out 0s;
	width: 100%;
}
.featured-blog:hover .featured-blog-details {
  bottom: 0;
}