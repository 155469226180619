/*
 * about.less
 * -----------------------------------------------
*/

/* -------- Bg Img Box ---------- */
.bg-img-box {
	position: relative;

	.caption {
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 20px;
		position: absolute;
		top: 0;

		.title {
			font-size: 30px;
			line-height: 39px;
		}

		p {
			max-width: 280px;
		}
	}
}
.newcomer {
  position: relative;
  overflow: hidden;
}
.newcomer img {
  transition: all 500ms ease 0s;
}

.newcomer-overlay {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(1, 0, 1, 0.6);
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: all 400ms ease-in-out 0s;
}
.newcomer-overlay::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.newcomer-inner {
    color: @white;
    display: inline-block;
    /* padding: 20px 0; */
    text-align: center;
    vertical-align: middle;    
    width: 98%;
}
.solar-icon-box {
  position: relative;
  .solar-in-icon {
    left: 0;
    position: absolute;
    right: 0;
    top: -58px;
  }
  .icon-detail {
    padding: 65px;
  }
}