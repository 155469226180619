/*
 * Shortcode: pricing.less
 * -----------------------------------------------
*/
.pricing-table {
	margin-bottom: 30px;
	position: relative;
	.table-price {
		color: @black-111;
		font-size: 45px;

		span {
			font-size: 13px;
			vertical-align: middle;
		}
	}

	&.featured {
		border: 1px solid @black-111;
	}

	.table-type {
		display: inline;
	}

	.btn-signup {
		margin-right: 0;
		width: 100%;
	}

	.table-list {
		list-style: outside none none;
		padding: 10px 0 10px 20px;

		li {
			border-bottom: 0;
			font-size: 12px;
			padding: 10px 0;

			i {
			  	margin-right: 8px;
			}
		}
	}

	.package-type {
		span {
			position: relative;
			padding-left: 15px;
			margin-left: 10px;
			
			&::after {
				background: #2d915b;
				content: "";
				height: 20px;
				left: -20px;
				position: absolute;
				top: 11px;
				width: 20px;
			}
		}
	}
}

.price .currency {
    color: #d9d9d9;
    vertical-align: super;
}

.price-table-new table tr td {
	line-height: 5;
}
@media only screen and (max-width : 901px) {
	.price-table-new table tr td {
	line-height: 1;
	}
}





