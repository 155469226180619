@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        ecosolar
Version:        1.0
Last change:    12.07.2016
Primary use:    ecosolar - Nonprofit Environment Recyling Solar HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-eco-set2.css";
@import "../css/flaticon-eco-set1.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Trirong:100,200,300,400,500,600,700,800,900);

 
// Table of contents
@import "less-ecosolar/table-of-content.less";

 
// Initialize Variables
@import "less-ecosolar/variables.less";
@import "less-ecosolar/mixins.less";
// Typography
@import "less-ecosolar/typography.less";

// Common Styles
@import "less-ecosolar/common.less";
@import "less-ecosolar/extra.less";
@import "less-ecosolar/overlay.less";

// Header
@import "less-ecosolar/header.less";

// Nav
@import "less-ecosolar/nav.less";

// Content Blocks
@import "less-ecosolar/topbar.less";
@import "less-ecosolar/inner-header-title.less";
@import "less-ecosolar/vertical-nav.less";
@import "less-ecosolar/menu-full-page.less";
@import "less-ecosolar/boxed-layout.less";
@import "less-ecosolar/form.less";
@import "less-ecosolar/side-push-panel.less";
@import "less-ecosolar/box-hover-effect.less";
@import "less-ecosolar/gallery-isotope.less";
@import "less-ecosolar/sliders.less";
@import "less-ecosolar/home.less";
@import "less-ecosolar/about.less";
@import "less-ecosolar/campaign.less";
@import "less-ecosolar/volunteer.less";
@import "less-ecosolar/contact.less";
@import "less-ecosolar/event.less";
@import "less-ecosolar/practice-area.less";
@import "less-ecosolar/services.less";
@import "less-ecosolar/job.less";
@import "less-ecosolar/shop.less";
@import "less-ecosolar/progress-bar.less";
@import "less-ecosolar/blog.less";
@import "less-ecosolar/causes.less";

// Shortcodes
@import "less-ecosolar/shortcodes.less";


// Widgets
@import "less-ecosolar/widgets.less";


// Footer
@import "less-ecosolar/footer.less";